<template>
  <a-row>
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" v-if="statePaginate.showLoader">
      <a-spin size="small" style="position:relative; left: 45%;margin-top: 2rem;" />
    </a-col>
  </a-row>
  <a-row v-if="state.events.length && !statePaginate.showLoader">
    <a-col
      :xxl="7"
      :lg="8"
      :md="12"
      :sm="24"
      :xs="24"
      class="box-event"
      v-for="event in state.events"
      :key="event">
        <div class='event--card'>
          <div class='card__img' @click.stop="sendToEventDetails(event.id)">
            <img
              :src='event.front_page ? state.base_url+event.front_page : "https://via.placeholder.com/448x248"'
              width={448}
              height={248}
              alt="imagen"
            />
          </div>
          <div class='card__main'>
            <a-row :gutter="25" class="card-container_body">
              <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                <a-row class="list-data"  style="height: 50px">
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24">
                    <h3 class="break-text-two-lines title-card">
                      {{ event.title }}
                    </h3>
                  </a-col>
                </a-row>
                <a-row class="list-data mt-2" style="height: 70px">
                  <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="">
                    <span class="break-text-three-lines desctiption-card">
                      {{ event.description }}
                    </span>
                  </a-col>
                </a-row>
                <a-row class="list-data"  style="height: 50px">
                  <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                    <img class="logo" width="12" height="14" :src="require('@/static/img_events/SEJ enventos_mis eventos.svg')" alt="event date" />
                  </a-col>
                  <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                    <span class="date-card">
                      {{ formatDate(event.event_date) }}, {{ removeSeconds(event.event_hour) }}hrs.
                    </span>
                  </a-col>
                </a-row>
                <a-row class="list-data">
                  <a-col :xxl="2" :lg="2" :md="2" :xs="2">
                    <img :src="require(`@/static/img_events/SEJ_Eventos_asistentes_black.svg`)"
                          alt="logo" width="12" height="14" />
                  </a-col>
                  <a-col :xxl="22" :lg="22" :md="22" :xs="22">
                    <span class="date-card">
                      {{ event.participants_count }} registros
                    </span>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </div>
          <div class='line'/>
          <div class='card__footer' >
            <a-row class="card_footer list-data" style="padding-bottom: 1rem;padding-top: 1rem;text-align: end;">
              <a-col :xxl="8" :lg="12" :md="12" :sm="24" :xs="24" class="mt-2">
                <router-link
                  v-if="(isDateAvailable(event.event_date) || event.status == 'Finalizado') && event.grouped_events == 0"
                  to="to"
                  @click="handleRouterView(`/register-assistance/list/${event.id}`)">
                  <button class="btn-outlet-white title-normal">
                      <img class="logo" width="20" height="20" :src="require('@/static/img_events/Eventos_ver_formulario_red.svg')" alt="event date" />
                      Ver lista
                  </button>
                </router-link>
                <router-link
                  v-if="(isDateAvailable(event.event_date) || event.status == 'Finalizado') && event.grouped_events == 1"
                  to="to"
                  @click="handleRouterView(`/register-assistance/subevents/${event.id}`)">
                  <button class="btn-outlet-white title-normal">
                      <img class="logo" width="20" height="20" :src="require('@/static/img_events/Eventos_ver_formulario_red.svg')" alt="event date" />
                      Ver lista
                  </button>
                </router-link>
              </a-col>
                <a-col :xxl="16" :lg="12" :md="12" :sm="24" :xs="24" class="mt-2">
                  <router-link
                    v-if="isDateAvailable(event.event_date) && !isVirtualEvent(event)"
                    to="to" 
                    @click="handleRouterView(`/register-assistance/${event.id}`)">
                    <button class="btn-outlet-red title-normal">
                        <img class="logo" width="20" height="20" :src="require('@/static/img_events/SEJ_Eventos_escanear_white.svg')" alt="event date" />
                        Registrar asistencia
                    </button>
                  </router-link>
              </a-col>
            </a-row>
          </div>
        </div>
    </a-col>
    <br>
    <a-col
      v-if="statePaginate.totalPages > 1 && statePaginate.page < statePaginate.totalPages && state.events"
      :xxl="24" :lg="24" :md="124" :xs="24"
      style="text-align:center; margin-bottom: 2rem;margin-top: 2rem;">
      <span class="title-more-events" v-on:click="showMoreElements">Ver más</span>
    </a-col>
  </a-row>
  <a-row :gutter="25" v-if="!state.events.length">
    <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;">
      <span class="title-without">
        No tienes eventos asignados
      </span>
    </a-col>
  </a-row>
</template>

<script>

import { useRouter } from 'vue-router';
import { reactive } from 'vue';
import { Notification } from 'ant-design-vue';

import { getItem } from '@/utility/localStorageControl';
import { RegisterStyleCard, RegisterStyleCardYellow } from '../../style';
import { getRegisterAttendanceEvents } from '@/services/events';
import { formatDate, removeSeconds } from '@/utility/utility';
import { getParticipantsCountByEvents } from '@/services/home';

const RegisterAssistantsEventComponent = {
  name: 'RegisterAssistantsEventComponent',
  components: { RegisterStyleCard, RegisterStyleCardYellow },
  props: {
    search: String,
  },
  watch: {
    search: function (newValue, oldValue) {
      console.log(newValue, oldValue);
      this.getRegisterBySearch(newValue);
    },
  },
  async setup(props) {
    console.log(props)
    const router = useRouter();

    const statePaginate = reactive({
      page: 1,
      totalPages: 1,
      showLoader: true,
      timer: null
    })

    const getRegisterAttendanceEventsFunc = async (numberPage, search) => {
      let events = [];
      await getRegisterAttendanceEvents(numberPage, search).then((response) => {
        if(response.success){
          events = JSON.parse(JSON.stringify(response.data.data));
          statePaginate.totalPages = response.data.last_page;
          statePaginate.showLoader = false;
          statePaginate.page = 1;
        } else {
          showNotification('error', 'Error', 'Oops ocurrió un error inesperado');
        }
      }).catch(() => {
        showNotification('error', 'Error', 'Oops ocurrió un error inesperado');
      })
      return events;
    }

    const state = reactive({
      roles: getItem("roles"),
      events: await getRegisterAttendanceEventsFunc(1, ''),
      base_url: process.env.VUE_APP_BASE_URL,
    });

    const sendToEventDetails = (idEvent) => {
      if(statePaginate.timer){
        clearInterval(statePaginate.timer);
      }
      router.push({name: 'eventDetail', params: { id: idEvent }})
    }

    const showNotification = (type, message, description) => {
      Notification[type]({
        message,
        description,
      });
    }

    statePaginate.timer = setInterval(() => {
      let ids = state.events.map(event => event.id);
      //console.log(ids)
      getParticipantsCountByEvents(ids)
        .then((response) => {
          if (response.success) {
            console.log("aqui timer");
            state.events.forEach(event => {
              event.participants_count = response.data[event.id];
            });
          }
        })
        .catch(() => {});
    }, 30000);

    const isDateAvailable = (dateFinish) => {
      let actualDate = new Date();
      actualDate = [
        actualDate.getFullYear(),
        '-',
        String(actualDate.getMonth() + 1).padStart(2, '0'),
        '-',
        String(actualDate.getDate()).padStart(2, '0'),
      ].join('');
      return dateFinish === actualDate;
    }

    const showMoreElements = async () => {
      statePaginate.page += 1;
      await getRegisterAttendanceEvents(statePaginate.page, props.search).then((response) => {
        if(response.success){
          if(response.data.data.length){
            response.data.data.forEach((event) => {
              state.events.push(event);
            })
          }
        } else {
          showNotification('error', 'Error', 'Oops ocurrió un error inesperado');
        }
      }).catch(() => {
        showNotification('error', 'Error', 'Oops ocurrió un error inesperado');
      })
    }

    const getRegisterBySearch = async (search) => {
      state.events = await getRegisterAttendanceEventsFunc(1, search);
    }

    const handleRouterView = (route) => {
      if(statePaginate.timer){
        clearInterval(statePaginate.timer);
      }
      router.push({ path: route });
    }

    const isVirtualEvent = (event) => {
      for(let i = 0; i < event.headquarters.length; i++){
        if(event.headquarters[i].type == "virtual") return true;
      }
      return false;
    }
    
    return {
      state,
      statePaginate,
      formatDate,
      sendToEventDetails,
      isDateAvailable,
      showMoreElements,
      getRegisterBySearch,
      handleRouterView,
      removeSeconds,
      isVirtualEvent
    };
  },
};

export default RegisterAssistantsEventComponent;
</script>

<style lang="scss" scoped>
.title-card{
  font-family: 'Avenir Heavy';
  font-weight: 600;
  font-size: 18px
}
.desctiption-card{
  color: #7B868C;
  font-size: 14px;
  font-weight: 400;
  font-family:'Avenir Roman';
}
.date-card{
  font-size: 13px;
  font-weight: 600;
  font-family:'Avenir Heavy';
}
button{
    border-radius: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-family: 'Avenir Heavy';
}
.btn-outlet-white{
    background: white;
    color: #E8394D;
    border: 1px solid #E8394D;
}
.btn-outlet-red{
    background: #E8394D;
    color: white;
    border: 1px solid #E8394D;
}
.title-more-events{
  color: #3FA7E1;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.card__img{
  border-radius: 20px 20px 0px 0px;
  cursor: pointer;
  img {
    border-radius: 20px 20px 0px 0px;
    object-fit: cover;
    width: 100%;
    height: 248px;
  }
}

.event--card{
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  background: white;
  margin: 1rem;
  font-family: 'Avenir';
}

.card__main{
  padding: 15px 32px 15px 32px;
  .card__title{
    color: #404040;
    font-size: 18px;
  }
}

.card__description{
  color: #7B868C;
  font-size: 14px;
  text-align: left;
  min-height: 80px;
}

.card__date{
  color: #51AF2C;
  font-size: 12px;
}

.line{
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 10px;
}

.card__footer{
  padding: 0px 20px 15px 20px;
  width: 100%;
  height: 100px;
}

.break-text-three-lines{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; 
}

.break-text-two-lines{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
}
.title-without{
  font-size: 16px;
  font-weight: 600;
}
@media only screen and (max-width: 767px){
  .card__footer{
    height: 150px;
  }
}
</style>